<template>
  <div class="page">
    <div class="main">
      <div class="title">轮播图管理</div>
      <el-row class="row-content">
        <el-button type="primary" @click="handleAdd" size="mini">新增</el-button>
      </el-row>
      <el-table
        :data="tables.tableData"
        v-loading="tables.listLoading"
        border
        style="width: 100%;"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="url" label="跳转地址" width="250">
        </el-table-column>
        <el-table-column
          prop="img"
          label="图片"
          width="250"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <img :src="scope.row.img" height="80" />
          </template>
        </el-table-column>
        <el-table-column prop="address" label="编辑">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <template>
          <div class="block">
            <el-pagination
              layout="prev, pager, next"
              :current-page="tables.currentPage"
              :total="tables.total"
              @current-change="handleCurrentChange"
            />
          </div>
        </template>
      </div>
    </div>

    <!--    表单提交-->
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.centerDialogVisible"
      :close-on-click-modal="false"
      width="65%"
      @closed="resetForm"
    >
      <el-form
        ref="dataForm"
        :inline="true"
        :model="formData"
        label-position="right"
        label-width="100px"
        style="padding-left: 40px"
      >
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="formData.type"
            class="filter-item"
            placeholder="类型"
            style="width: 300px"
          >
            <el-option :key="2" label="手机端轮播图" :value="2" />
            <el-option :key="1" label="商品宣传图" :value="1" />
            <el-option :key="0" label="首页" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="img">
          <el-upload
            action="https://bkgaoshou.com/index.php/admin/upload/images"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="upload_img"
          >
            <i class="el-icon-plus" />
            <img
              v-if="formData.img"
              :src="formData.img"
              class="avatar"
              width="200px"
            />
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转地址" prop="url">
          <el-input v-model="formData.url" style="width: 300px" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button v-if="dialog.title == '编辑'" @click="edit">确定</el-button>
        <el-button v-if="dialog.title == '新增'" @click="add">添加</el-button>
      </span>
    </el-dialog>
    <!--    表单提交-->
  </div>
</template>

<script>
//time: 2021/1/20
export default {
  name: "Info",
  data() {
    return {
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 10,
        total: 1,
        tableData: [],
      },
      dialog: {
        centerDialogVisible: false,
      },
      formData: {
        id: "",
        type: "",
        img: "",
        url: "",
        status: null,
      },
      dialogVisible: false,
      SubjectData: [],
      cityData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const data = {
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };
      this.$fecth.post("banner/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tables.tableData = data.lists;
          this.tables.pageSize = data.pageSize;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    add() {
      const data = {
        type: this.formData.type,
        img: this.formData.img,
        url: this.formData.url,
        status: this.formData.status,
      };
      this.$fecth.post("banner/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.resetForm();
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleAdd() {
      this.dialog.title = "新增";
      this.dialog.centerDialogVisible = true;
    },

    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };
      this.$fecth.post("banner/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit() {
      const data = {
        id: this.formData.id,
        type: this.formData.type,
        img: this.formData.img,
        url: this.formData.url,
        status: this.formData.status,
      };
      this.$fecth.post("banner/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.resetForm();
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("banner/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        type: null,
        img: "",
        url: "",
        status: "",
      };
      this.dialog.centerDialogVisible = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      if (isJPG == "image/jpeg" || isJPG == "image/png") {
        // console.log(true);
      } else {
        this.$message.error("上传图片格式错误!");
        return false;
      }
    },
    upload_img(file) {
      if (file.code == 200) {
        this.formData.img = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .row-content {
    background: #fff;
    padding: 20px;
  }
</style>